<template>
  <div class="">

    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addRole(item)">
          </fa>&nbsp;
          {{item.name}}
        </li>
      </ul>
    </div>

    อำเภอ:
    <ul>
      <li v-for="district in districts" :key="district.id">
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer"
          @click="deleteRole(district.id)">
        </fa>&nbsp;
        {{district.name}}
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_DISTRICT,
  CREATE_DISTRICT,
  DESTROY_DISTRICT
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    countryAreaType: {
      type: String,
      required: true
    },
    countryAreaId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    districts () {
      return this.formData.districts.filter(v => {
        const isNameFound = v.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isNameFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_DISTRICT(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.districts
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addRole (district) {
      this.$apollo.mutate({
        mutation: CREATE_DISTRICT(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          countryAreaId: this.countryAreaId,
          countryDistrictId: district.id
        }
      })
      .then(() => {
        this.addRoles(district)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deleteRole (districtId) {
      this.$apollo.mutate({
        mutation: DESTROY_DISTRICT(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          countryAreaId: this.countryAreaId,
          countryDistrictId: districtId
        }
      })
      .then(() => {
        this.removeRoles(districtId)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitRoles (arr) {
      this.$emit('districts', arr)
    },
    addRoles (district) {
      const index = this.formData.districts.findIndex(v => v.id === district.id)
      if (index === -1) this.formData.districts.unshift(district)
    },
    removeRoles (districtId) {
      const index = this.formData.districts.findIndex(v => v.id === districtId)
      this.formData.districts.splice(index, 1)
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'name'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
