<template>
  <div class="">

    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addRole(item)">
          </fa>&nbsp;
          {{item.name}}
        </li>
      </ul>
    </div>

    จังหวัด:
    <ul>
      <li v-for="province in provinces" :key="province.id">
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer"
          @click="deleteRole(province.id)">
        </fa>&nbsp;
        {{province.name}}
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_PROVINCE,
  CREATE_PROVINCE,
  DESTROY_PROVINCE
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    countryAreaType: {
      type: String,
      required: true
    },
    countryAreaId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    provinces () {
      return this.formData.provinces.filter(v => {
        const isNameFound = v.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isNameFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_PROVINCE(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.provinces
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addRole (province) {
      this.$apollo.mutate({
        mutation: CREATE_PROVINCE(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          countryAreaId: this.countryAreaId,
          countryProvinceId: province.id
        }
      })
      .then(() => {
        this.addRoles(province)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deleteRole (provinceId) {
      this.$apollo.mutate({
        mutation: DESTROY_PROVINCE(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          countryAreaId: this.countryAreaId,
          countryProvinceId: provinceId
        }
      })
      .then(() => {
        this.removeRoles(provinceId)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitRoles (arr) {
      this.$emit('provinces', arr)
    },
    addRoles (province) {
      const index = this.formData.provinces.findIndex(v => v.id === province.id)
      if (index === -1) this.formData.provinces.unshift(province)
    },
    removeRoles (provinceId) {
      const index = this.formData.provinces.findIndex(v => v.id === provinceId)
      this.formData.provinces.splice(index, 1)
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'name'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
