<template>
  <div class="">

    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addRole(item)">
          </fa>&nbsp;
          {{item.code}} ({{item.name}})
        </li>
      </ul>
    </div>

    ผู้ติดต่อ:
    <ul>
      <li v-for="contact in contacts" :key="contact.id">
        <fa
          v-if="method ==='edit'"
          icon="trash"
          class="text-danger pointer"
          @click="deleteRole(contact.id)">
        </fa>&nbsp;
        {{contact.code}} ({{contact.name}})
      </li>
    </ul>
  </div>
</template>

<script>
import {
  LIST_CONTACT,
  CREATE_CONTACT,
  DESTROY_CONTACT
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    countryAreaType: {
      type: String,
      required: true
    },
    countryAreaId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    contacts () {
      return this.formData.contacts.filter(v => {
        const isCodeFound = v.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        const isNameFound = v.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isNameFound || isCodeFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: LIST_CONTACT(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.contacts
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addRole (contact) {
      this.$apollo.mutate({
        mutation: CREATE_CONTACT(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          countryAreaId: this.countryAreaId,
          contactId: contact.id
        }
      })
      .then(() => {
        this.addRoles(contact)
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deleteRole (contactId) {
      this.$apollo.mutate({
        mutation: DESTROY_CONTACT(this.templateType),
        variables: {
          countryAreaType: this.countryAreaType,
          countryAreaId: this.countryAreaId,
          contactId: contactId
        }
      })
      .then(() => {
        this.removeRoles(contactId)
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitRoles (arr) {
      this.$emit('contacts', arr)
    },
    addRoles (contact) {
      const index = this.formData.contacts.findIndex(v => v.id === contact.id)
      if (index === -1) this.formData.contacts.unshift(contact)
    },
    removeRoles (contactId) {
      const index = this.formData.contacts.findIndex(v => v.id === contactId)
      this.formData.contacts.splice(index, 1)
    },
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'name'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
